<template>
  <div>
    <UsersExerciseEntry />
  </div>
</template>
<script>
import UsersExerciseEntry from './UsersExerciseEntry.vue'

export default {
  components: {
    UsersExerciseEntry,
  },
}
</script>
